<template>
  <div>
    <b-card-code title="Enable/Disable">
      <b-card-text class="mb-0">
        <span>A single checkbox can be rendered with a switch appearance by setting the prop </span>
        <code>switch</code>
        <span> to </span>
        <code>true</code>
      </b-card-text>

      <!-- switch -->
      <div class="demo-inline-spacing">
        <b-form-checkbox
          checked="true"
          name="check-button"
          switch
          inline
        >
          Feature1
        </b-form-checkbox>
        <b-form-checkbox
          checked="false"
          name="check-button"
          switch
          inline
        >
          Feature2
        </b-form-checkbox>
        <b-form-checkbox
          checked="true"
          name="check-button"
          switch
          inline
          disabled
        >
          Feature 3
        </b-form-checkbox>

        <b-form-checkbox
          checked="false"
          name="check-button"
          switch
          inline
          disabled
        >
          Feature 4 Coming soon
        </b-form-checkbox>
      </div>

      <template #code>
        {{ codeBasic }}
      </template>
    </b-card-code>
    <b-card
      title="Bootsrap-vue Collapse"
    >
      <b-card-text>Easily toggle visibility of almost any content on your pages. Includes support for making accordions.</b-card-text>

      <!-- toggle button -->
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        Toggle Collapse
      </b-button>

      <b-collapse
        id="collapse-1"
        class="mt-2"
      >
        <b-card class="border mb-0">
          <b-card-text class="card-text">
            Collapse contents Here
          </b-card-text>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-toggle.collapse-1-inner
            size="sm"
            variant="outline-primary"
          >
            Toggle Inner Collapse
          </b-button>
          <b-collapse
            id="collapse-1-inner"
            class="mt-2"
          >
            <b-card class="border mb-0">
              Hello!
            </b-card>
          </b-collapse>
        </b-card>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BCollapse, BFormCheckbox, BCardText, BCard,
} from 'bootstrap-vue'
import store from '@/store'
import { codeBasic } from './code'

export default {
  components: {
    BCardCode,
    BFormCheckbox,
    BCard,
    BCollapse,
    BCardText,
  },
  data() {
    return {
      codeBasic,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
    max() {
      return 52
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
